var render = function render() {
  var _vm$exam, _vm$exam2, _vm$user, _vm$user2;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "e-container"
  }, [_c('div', {
    staticClass: "m-exam"
  }, [_c('el-tag', {
    attrs: {
      "size": "small",
      "type": "warning",
      "effect": "dark"
    }
  }, [_vm._v(_vm._s(_vm.examTypeMap[(_vm$exam = _vm.exam) === null || _vm$exam === void 0 ? void 0 : _vm$exam.type]))]), _c('span', {
    staticClass: "m-exam-name"
  }, [_vm._v(_vm._s((_vm$exam2 = _vm.exam) === null || _vm$exam2 === void 0 ? void 0 : _vm$exam2.name))])], 1), _c('el-container', {
    staticClass: "m-exam-wrapper"
  }, [_c('div', {
    staticClass: "m-container stu-container"
  }, [_c('div', {
    staticClass: "m-tag"
  }, [_vm._v("考试信息")]), _c('div', {
    staticClass: "stu-exam-info"
  }, [_c('div', [_c('div', [_vm._v("姓名：" + _vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name))]), _c('div', [_vm._v("身份证号：" + _vm._s((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.account))])]), _c('div', [_c('div', [_vm._v("考试题数：")]), _c('div', [_vm._v(_vm._s(_vm.qTypeMap[1]) + _vm._s(_vm.examInfo.q1Count || '--'))]), _c('div', [_vm._v(_vm._s(_vm.qTypeMap[2]) + _vm._s(_vm.examInfo.q2Count || '--'))]), _c('div', [_vm._v(_vm._s(_vm.qTypeMap[3]) + _vm._s(_vm.examInfo.q3Count || '--'))])]), _c('div', [_c('div', [_vm._v("考试时间：" + _vm._s(_vm.examInfo.time || '--'))]), _c('div', [_vm._v("满分" + _vm._s(_vm.examInfo.maxScore || '--') + "分，" + _vm._s(_vm.examInfo.maxScore * 6 / 10 || '--') + "分及格")])])])]), _c('div', {
    staticClass: "m-container exam-container"
  }, [_c('div', {
    staticClass: "m-tag"
  }, [_vm._v("考试题目")]), _c('div', {
    staticClass: "exam-info"
  }, [_c('div', {
    staticClass: "exam-content"
  }, [_vm._v(_vm._s(_vm.question.index + 1) + "、" + _vm._s(_vm.question.context))]), _c('div', {
    staticClass: "exam-options"
  }, _vm._l(_vm.question.options, function (item, i) {
    return _c('div', {
      key: 'option:' + i
    }, [_vm._v(" " + _vm._s(item.optionChoise) + "、" + _vm._s(item.optionMessage) + " ")]);
  }), 0), _vm.question.index !== null ? _c('el-container', {
    staticClass: "exam-operate"
  }, [_c('div', [_vm.question.correct === 1 ? _c('span', {
    staticClass: "correct-answer"
  }, [_vm._v("回答正确！")]) : _c('span', {
    staticClass: "wrong-answer"
  }, [_vm._v("回答错误！")])]), _c('div', [_vm._v(" 您的答案："), _c('span', {
    staticClass: "answer"
  }, [_vm._v(_vm._s(_vm.fixAnswerText(_vm.question.answer) || '未作答'))])]), _c('div', [_vm._v(" 正确答案："), _c('span', {
    staticClass: "answer"
  }, [_vm._v(_vm._s(_vm.fixAnswerText(_vm.question.rightAnswer)))])]), _c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        return _vm.showAnalysis(_vm.question.index);
      }
    }
  }, [_vm._v("解析")])], 1) : _vm._e()], 1)]), _c('div', {
    staticClass: "m-container question-container"
  }, [_c('div', {
    staticClass: "m-tag"
  }, [_vm._v("考试题号")]), _vm._l(_vm.questions, function (item, i) {
    return _c('div', {
      key: 'question:' + i,
      staticClass: "question-item",
      class: _vm.getQuestionStateStyle(item, i),
      on: {
        "click": function ($event) {
          return _vm.changeQuestion(i);
        }
      }
    }, [_vm._v(" " + _vm._s(i + 1) + " ")]);
  })], 2)]), _c('el-container', {
    staticClass: "m-exam-wrapper"
  }, [_c('div', {
    staticClass: "m-container time-container"
  }, [_c('div', {
    staticClass: "m-tag"
  }, [_vm._v("考试成绩")]), _c('div', {
    staticClass: "exam-score"
  }, [_vm._v(" " + _vm._s(_vm.examInfo.score) + " ")])]), _c('div', {
    staticClass: "m-container q-type-container"
  }, [_c('div', {
    staticClass: "m-tag"
  }, [_vm._v("试题提示信息")]), _vm.question.type === 1 ? [_c('div', [_vm._v("单选题，请选择你认为正确的答案")])] : _vm._e(), _vm.question.type === 2 ? [_c('div', [_vm._v("多选题，请选择合理正确的答案")])] : _vm._e(), _vm.question.type === 3 ? [_c('div', [_vm._v("判断题，请判断对错")])] : _vm._e()], 2), _c('div', {
    staticClass: "m-container q-exam-container"
  }, [_c('div', {
    staticClass: "buttons"
  }, [_vm.isFirstQuestion ? [_c('el-button', {
    key: "btn-pre-1",
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "medium",
      "disabled": ""
    }
  }, [_vm._v("上一题")])] : [_c('el-button', {
    key: "btn-pre-2",
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "medium"
    },
    on: {
      "click": _vm.preQuestion
    }
  }, [_vm._v("上一题")])], _vm.isLastQuestion ? [_c('el-button', {
    key: "btn-next-1",
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "medium",
      "disabled": ""
    }
  }, [_vm._v("下一题")])] : [_c('el-button', {
    key: "btn-next-2",
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "medium"
    },
    on: {
      "click": _vm.nextQuestion
    }
  }, [_vm._v("下一题")])], _c('el-button', {
    attrs: {
      "type": "success",
      "plain": "",
      "size": "medium"
    },
    on: {
      "click": _vm.backToExams
    }
  }, [_vm._v("返回首页")])], 2)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };