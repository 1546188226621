import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
export default {
  name: 'studentExamAnalysis',
  data() {
    return {
      examTypeMap: {
        1: '正式考试',
        2: '模拟考试'
      },
      qTypeMap: {
        1: '单选题',
        2: '多选题',
        3: '判断题'
      },
      examId: null,
      exam: null,
      examScore: null,
      examDeadline: null,
      user: null,
      question: {
        index: null,
        type: null,
        context: null,
        answer: null,
        rightAnswer: null,
        correct: null,
        options: []
      },
      examInfo: {
        q1Count: null,
        q2Count: null,
        q3Count: null,
        time: null,
        maxScore: null,
        score: null
      },
      questions: [],
      answers: []
    };
  },
  beforeMount() {
    try {
      const user = window.localStorage.getItem('user');
      const userInfo = JSON.parse(user);
      if (!userInfo || userInfo.type !== 2) {
        this.$router.go(-1);
        return;
      }
      this.user = userInfo;
    } catch (e) {
      this.$router.go(-1);
      return;
    }
    const {
      id
    } = this.$route.query;
    if (!id) {
      this.$router.go(-1);
      return;
    }
    this.examId = id;
  },
  async mounted() {
    const exam = await this.queryExam({
      id: this.examId
    });
    if (!exam) {
      this.$router.go(-1);
      return;
    }
    if (exam.type === 1) {
      this.$router.go(-1);
      return;
    }
    const examDeadline = await this.queryExamDeadline({
      id: exam.id
    });
    if (examDeadline.startLeftSeconds > 30) {
      this.$router.go(-1);
      return;
    }
    const examScore = await this.queryUserExamScore({
      examId: this.examId
    });
    const questions = await this.queryExamQuestionList({
      id: exam.id
    });
    const examInfo = Object.assign({}, this.calExamTime(examDeadline), this.calExamScore(examScore), this.calQCount(questions), this.calMaxScore(questions));
    const answers = await this.queryUserExamAnswerStateList({
      examId: exam.id
    });
    this.exam = exam;
    this.examDeadline = examDeadline;
    this.questions = questions;
    this.examInfo = examInfo;
    this.answers = answers;
    this.question = await this.getQuestion(0);
  },
  computed: {
    isFirstQuestion() {
      return this.question.index <= 0;
    },
    isLastQuestion() {
      return this.question.index >= this.questions.length - 1;
    }
  },
  methods: {
    async queryExam(data) {
      const examResult = await this.Api.student.queryExam(data);
      return examResult.data;
    },
    async queryUserExamScore(data) {
      const scoreResult = await this.Api.student.queryUserExamScore(data);
      const score = scoreResult.data;
      return score;
    },
    async queryExamDeadline(data) {
      const examDeadlineResult = await this.Api.student.queryExamDeadline(data);
      return examDeadlineResult.data;
    },
    async queryExamQuestionList(data) {
      const questionsResult = await this.Api.student.queryExamQuestionList(data);
      return questionsResult.data;
    },
    async queryUserExamAnswerStateList(data) {
      const answersResult = await this.Api.student.queryUserExamAnswerStateList(data);
      return answersResult.data;
    },
    async queryPaperQuestion(data) {
      const questionResult = await this.Api.student.queryPaperQuestion(data);
      return questionResult.data;
    },
    fixAnswerText(answer) {
      if (answer === 'T') {
        return '正确';
      }
      if (answer === 'F') {
        return '错误';
      }
      return answer;
    },
    calExamScore(examScore) {
      return {
        score: examScore.score + '分'
      };
    },
    calExamTime(examDeadline) {
      const examSeconds = examDeadline.endLeftSeconds - examDeadline.startLeftSeconds;
      const hours = parseInt(examSeconds / 3600);
      const minutes = parseInt(examSeconds % 3600 / 60);
      const hourTime = hours > 0 ? `${hours}小时` : '';
      const minuteTime = minutes > 0 ? `${minutes}分钟` : '';
      return {
        time: hourTime + minuteTime
      };
    },
    calQCount(questions) {
      const q1Count = questions.filter(q => q.type === 1).length + '题';
      const q2Count = questions.filter(q => q.type === 2).length + '题';
      const q3Count = questions.filter(q => q.type === 3).length + '题';
      return {
        q1Count,
        q2Count,
        q3Count
      };
    },
    calMaxScore(questions) {
      const maxScore = questions.reduce(function (pre, cur) {
        return pre + cur.score;
      }, 0);
      return {
        maxScore
      };
    },
    getQuestionStateStyle(question, index) {
      if (this.exam.type === 1) {
        if (this.question.index === index) {
          return 'question-active';
        }
        if (this.answers.filter(a => a.questionId === question.questionId).length > 0) {
          return 'question-submitted';
        }
      }
      if (this.exam.type === 2) {
        if (this.question.index === index) {
          return 'question-active';
        }
        const answer = this.answers.filter(a => a.questionId === question.questionId).pop();
        if (answer) {
          if (answer.correct === 1) {
            return 'question-correct';
          }
          return 'question-wrong';
        }
        return 'question-wrong';
      }
      return '';
    },
    preQuestion() {
      this.changeQuestion(this.question.index - 1);
    },
    nextQuestion() {
      this.changeQuestion(this.question.index + 1);
    },
    async changeQuestion(index) {
      this.question = await this.getQuestion(index);
    },
    async getQuestion(index) {
      const q = this.questions[index].question;
      const a = this.answers.filter(item => item.questionId === q.id)[0];
      const question = {
        index: index,
        answer: a === null || a === void 0 ? void 0 : a.answer,
        rightAnswer: q.answer,
        correct: a === null || a === void 0 ? void 0 : a.correct,
        type: q.type,
        context: q.context,
        options: JSON.parse(q.options)
      };
      return question;
    },
    showAnalysis(index) {
      var _question$analysis;
      const question = this.questions[index].question;
      this.$alert((_question$analysis = question.analysis) !== null && _question$analysis !== void 0 ? _question$analysis : '无', '解析', {
        confirmButtonText: '确定'
      });
    },
    backToExams() {
      this.$router.push({
        path: '/student/exams'
      });
    }
  }
};